#AppContainer::-webkit-scrollbar {
  display: none;
}

#hideScrollbar::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #86cde7 !important;
}

.searchingHeader {
  width: 100%;
  height: 64px;
  background-color: #0c5ccc;
}

.searchHeaderText {
  height: 26px;
  font-size: 18px;
  color: #ffffff;

  float: right;
  padding: 20px 20px 0 20px;
}

.MainText {
  position: absolute;
  top: 40%;
  left: 40%;
  color: red;
  font-size: 20px;
}

.NumberInputBlock {
  text-align: center;
  width: 12px;
  font-size: 18px;
  color: #000000;
  border: none;
}

.codeSelect {
  font-size: 14px;
  color: #b0bec5;

  /*float: right;*/

  /*display: inline-block;*/
}

.valueSelect {
  font-size: 18px;
  color: #000000;

  /*float: left;*/
  /*width: 200px;*/
  /*display: inline-block;*/
}

.containerSelect {
  /*width: 200px;*/
  /*text-align:center;*/
}

.slick-slide {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.slick-track {
  margin-left: 0 !important;
}
